<template>
  <div id="container">
    <div id="policy">
      <h1>{{ $t('title') }}</h1>
      <p>{{ $t('subtitle') }}</p>
      <ol>
        <li>
          <b>{{ $t('t0.title') }}</b>
          <p>{{ $t('t0.subtitle') }}</p>
        </li>
        <li>
          <b>{{ $t('t1.title') }}</b>
          <p>{{ $t('t1.subtitle') }}</p>
        </li>
        <li>
          <b>{{ $t('t2.title') }}</b>
          <p>{{ $t('t2.subtitle') }}</p>
        </li>
        <li>
          <b>{{ $t('t3.title') }}</b>
          <p>{{ $t('t3.subtitle') }}</p>
        </li>
        <li>
          <b>{{ $t('t4.title') }}</b>
          <p>{{ $t('t4.subtitle') }}</p>
        </li>
        <li>
          <b>{{ $t('t5.title') }}</b>
          <p>{{ $t('t5.info-1') }}</p>
          <ul>
            <li>
              <p>{{ $t('t5.t5-1.text') }}</p>
            </li>
            <li>
              <p>{{ $t('t5.t5-2.text') }}</p>
            </li>
          </ul>
        </li>
      </ol>
      <p>{{ $t('info-2') }}</p>
    </div>
  </div>
</template>
<script>
  export default {}
</script>
<style scoped>
p,
h1,
b {
  font-family: 'Roboto', sans-serif;
}

#policy {
  padding: 32px;
  max-width: 1200px;
}

#container {
  margin: 0;
  padding: 16px;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

ol li {
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}

li > p {
  font-weight: normal;
}

ol,
ul {
  padding-left: 16px;
}
</style>
<i18n>
  {
  "it": {
    "title": "Informativa ai sensi dell’art. 13, D.Lgs. 30 giugno 2003, n. 196 e dell’art. 13 del Regolamento (UE) 2016/679",
    "subtitle": "Minnovi S.r.l., con sede legale a Treviso, in via Cavalleggeri 14, C.F. e P.IVA 04742950266, e-mail info@minnovi.com.it, in persona del legale rappresentate pro tempore (d’ora in avanti la “Società”), nella sua qualità di titolare del trattamento dei dati, ai sensi e per gli effetti del D. Lgs. 30 giugno 2003 n. 196 e successive modifiche (d’ora in avanti “Decreto”) e del Regolamento UE 2016/679 (d’ora in avanti “Regolamento”), con la presente Le fornisce le dovute informazioni in ordine alle finalità e modalità del trattamento dei Suoi dati personali, all’ambito di comunicazione e diffusione degli stessi nonché alla natura del loro conferimento.",
    "t0": {
      "title": "Finalità del trattamento",
      "subtitle": "I dati da Lei forniti attraverso l’uso dell’app “SanyBeach” saranno trattati unicamente per consentire l’uso dell’app stessa."
    },
    "t1": {
      "title": "Natura del conferimento",
      "subtitle": "Il conferimento dei dati per le finalità di cui al punto 1 è un obbligo contrattuale e il mancato conferimento comporterà l’impossibilità di utilizzare l’app."
    },
    "t2": {
      "title": "Consenso",
      "subtitle": "Lei ha diritto di revocare il proprio consenso in qualsiasi momento. La revoca del consenso non pregiudica la liceità del trattamento basata sul consenso prima della revoca."
    },
    "t3": {
      "title": "Modalità del trattamento e tempo di conservazione dei dati",
      "subtitle": "Il trattamento sarà effettuato con l’ausilio di sistemi informatici a opera di soggetti di ciò appositamente incaricati nel rispetto delle misure di sicurezza prescritte dal Regolamento. In taluni casi il trattamento potrà essere effettuato anche in forma cartacea, sempre ad opera di soggetti di ciò incaricati e sempre nel rispetto della normativa vigente in tema di privacy. I dati personali verranno conservati dalla Società per un tempo massimo di dieci anni, conformemente alla normativa vigente in merito alla conservazione dei documenti."
    },
    "t4": {
      "title": "Ambito di comunicazione e diffusione",
      "subtitle": "I dati non saranno comunicati a terzi."
    },
    "t5": {
      "title": "Diritti dell’interessato",
      "info-1": "Conformemente a quanto previsto dall’art. 13, paragrafo 2 del Regolamento, La Società La informa che Lei ha diritto di:",
      "t5-1": {
        "text": "chiedere al titolare del trattamento l’accesso ai dati personali e la rettifica o la cancellazione degli stessi o la limitazione del trattamento che lo riguardano o di opporsi al loro trattamento, oltre al diritto della portabilità dei dati;"
      },
      "t5-2": {
        "text": "proporre reclamo a una autorità di controllo"
      }
    },
    "info-2": "Lei potrà far valere i diritti di cui sopra, quelli di cui agli artt. 15 e ss. del Regolamento nonché quelli di cui agli artt. 7 e ss. del Decreto e successive modifiche, rivolgendosi al titolare del trattamento dei dati all’indirizzo sopra indicato."
  },
  "en": {
    "title": "Information pursuant to art. 13, Legislative Decree 30 June 2003, n. 196 and art. 13 of Regulation (EU) 2016/679",
    "subtitle": "Minnovi S.r.l., with registered office in Treviso, in via Cavalleggeri 14, C.F. and VAT number 04742950266, e-mail info@minnovi.com.it, in the person of the legal representative pro tempore (henceforth the \"Company\"), in its capacity as data controller, pursuant to and for the purposes of of Legislative Decree 30 June 2003 n. 196 and subsequent amendments (hereafter \"Decree\") and EU Regulation 2016/679 (hereafter \"Regulation\"), hereby provides you with the necessary information regarding the purposes and methods of processing your personal data, the scope of communication and dissemination of the same as well as the nature of their provision.",
    "t0": {
      "title": "Purpose of the treatment",
      "subtitle": "The data you provide through the use of the \"SanyBeach\" app will be processed only to allow the use of the app itself."
    },
    "t1": {
      "title": "Nature of the provision",
      "subtitle": "The provision of data for the purposes referred to in point 1 is a contractual obligation and failure to provide it will make it impossible to use the app."
    },
    "t2": {
      "title": "Consent",
      "subtitle": "You have the right to withdraw your consent at any time. The withdrawal of consent does not affect the lawfulness of the treatment based on consent before the revocation."
    },
    "t3": {
      "title": "Processing methods and data retention time",
      "subtitle": "The treatment will be carried out with the aid of IT systems by persons specifically appointed to do so in compliance with the security measures prescribed by the Regulation. In some cases, the processing may also be carried out in paper form, always by persons in charge of this task and always in compliance with current legislation on privacy. Personal data will be kept by the Company for a maximum period of ten years, in accordance with current legislation on the conservation of documents."
    },
    "t4": {
      "title": "Scope of communication and diffusion",
      "subtitle": "The data will not be disclosed to third parties."
    },
    "t5": {
      "title": "Rights of the interested party",
      "info-1": "In accordance with the provisions of art. 13, paragraph 2 of the Regulations, the Company informs you that you have the right to:",
      "t5-1": {
        "text": "ask the data controller to access personal data and to rectify or delete them or limit their processing or to oppose their processing, in addition to the right to data portability;"
      },
      "t5-2": {
        "text": "lodge a complaint with a supervisory authority"
      }
    },
    "info-2": "You can assert the above rights, those referred to in articles 15 and following of the Regulation as well as those referred to in Articles 7 and following of the Decree and subsequent amendments, by contacting the data controller at the address indicated above."
  }
}
</i18n>
